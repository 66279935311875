import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Section, Button } from '../../Core';
import { navigate } from 'gatsby';
import image1 from './../../../assets/image/rebrand/jpg/ryan.jpg';

const ContactBusiness = (props) => {
    return (
        <Section>
            <Container>
                <Row>
                    <Col sm={12} md={6}>
                        <img src={image1} className="w-100 pb-5"/>
                    </Col>
                    <Col sm={12} md={6}>
                        <h2 >
                            There’s no one<br />Better to help you.
                        </h2>
                        <p className="mt-50 mb-50">
                            Please call on us anytime. And when we say call, we mean phone.
                            We’ll actually answer.
                            You can email too, or try one of those online chat things.
                            No bots, talk to flesh and blood humans.
                        </p>
                        <Row>
                            <Col sm={12} md={6}>
                                <Row>
                                    <Col sm={12}>
                                        <Button onClick={() => (typeof window !==  "undefined") && document.getElementsByClassName('UR_iWidget')[0].click()} className="medium-button blue-bg">
                                            Chat&nbsp;Now
                                        </Button>
                                    </Col>
                                    <Col sm={12} className="mt-3">
                                        <p>
                                            Our team of experts is always ready and willing to help out with your home broadband by email, chat or phone.
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12} md={6}>
                                <Row>
                                    <Col sm={12}>
                                        <Button
                                            onClick={() => navigate('https://support.youfibre.com/')}
                                            className="medium-button dark-bg"
                                        >
                                            FAQ
                                        </Button>
                                    </Col>
                                    <Col sm={12} className="mt-3">
                                        <p>
                                            We’d like to to hear from you. Better to ask the way than go astray.
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Section>
    )
};

export default ContactBusiness;
